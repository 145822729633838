// @flow

import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Row, Col, ToggleButtonGroup, ToggleButton, Alert } from 'react-bootstrap'
import styled from 'styled-components'
import { useFormik } from 'formik'
import TextField from '@mui/material/TextField'

import { togglePageDialog, updateCartridge, getDevice } from 'actions'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { DEVICE_DETAILS_PERMISSIONS_LIST, UNSPECIFIED_STR, CUSTOM_STR } from 'appConstants'

import { Button, FuelCartridge, PermissionCheck } from 'components/ReUsable'

import { roundedNumber } from 'utils/helpers'
import { CartridgeType } from 'types/cartridge'
import { SectionTitle } from 'components/ReUsable/Dialog'
import SelectElement from 'components/ReUsable/SelectElement'

const SectionFrame = styled.div`
  color: var(--color-text);
  line-height: 1.5rem;
  border: var(--color-table-border) 1px solid;
  border-radius: var(--dim-border-radius);
  padding: 1rem;
  flex: 1 1 auto;
`

const ContentDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const NavigationArrow = styled(FontAwesomeIcon)`
  color: var(--color-primary);
  font-size: 2.5rem;
  transition: all 0.3s;
  flex: 0 0 4rem;

  &:hover,
  &:active {
    color: var(--color-primary-hover);
  }
`

const PreviousCartridgeButton = styled(NavigationArrow)`
  &:hover,
  &:active {
    transform: translate(-0.2rem);
  }
`

const NextCartridgeButton = styled(NavigationArrow)`
  &:hover,
  &:active {
    transform: translate(0.2rem);
  }
`

const CartridgeButtonDisabled = styled(NavigationArrow)`
  color: var(--color-disabled);

  &:hover,
  &:active {
    color: var(--color-disabled);
  }
`

const ButtonRight = styled(Button)`
  float: right;
  margin: 1rem 0 0 0;
`

const FuelCartridgeInline = styled.div`
  display: inline-block;
  float: right;
`

const UpToMessage = styled.span`
  font-size: 12px;
`

const StyledCol = styled(Col)`
  padding-top: 7px;
`

export const StyledTextInput = styled(TextField)`
  .MuiInputBase-input {
    color: var(--color-text);
    border: 1px solid var(--color-border-light);
    border-radius: var(--dim-border-radius);

    &:hover {
      box-shadow: rgba(101, 179, 48, 0.25) 0px 0px 0px 0.2rem;
    }

    &:focus {
      box-shadow: rgba(101, 179, 48, 0.25) 0px 0px 0px 0.2rem;
    }
  }

  fieldset {
    border-width: 0px !important;
  }
`

type Props = {
  fuelCartridge: any
  previousId: string | null
  nextId: string | null
  maxNumberOfCartridges: number
  permissions: any
}

const FuelCartridgesEditDialog = (props: Props) => {
  const dispatch = useDispatch()
  const { fuelCartridge, previousId, nextId, maxNumberOfCartridges, permissions } = props

  const deviceSerialNumber = useSelector((state: any) => state.devices.current.serialNumber)
  const cartridge = useSelector((state: any) => state.devices.updateCartridge)
  const { loading, status, message } = cartridge

  const _updateCartridge = (payload: { deviceSerialNumber: string; cartridge: CartridgeType }) => dispatch(updateCartridge(payload))
  const _togglePageDialog = (payload?: any) => dispatch(togglePageDialog(payload))
  const _getDevice = (payload: any) => dispatch(getDevice(payload))

  const formik = useFormik({
    initialValues: { ...fuelCartridge },
    onSubmit: (values) => {
      _updateCartridge({
        deviceSerialNumber,
        cartridge: { ...values, portNumber: fuelCartridge.portNumber }
      })
    }
  })

  const getValidValue = (value: any) => {
    if (isNaN(value) || value < 0 || value > 100) {
      return roundedNumber(formik.values.fuelLevel)
    }

    return value
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    formik.setFieldValue(event.target.name, getValidValue(event.target.value))
  }

  const handleTypeChange = (event: any) => {
    formik.setFieldValue(event.target.name, event.target.value)
    if (event.target.value?.toLowerCase() === UNSPECIFIED_STR) {
      formik.setFieldValue('fuelLevel', 0)
    } else {
      formik.setFieldValue('fuelLevel', 100)
    }
  }

  const [showMessage, setShowMessage] = useState(false)

  useEffect(() => {
    if (loading === false && message) {
      setShowMessage(true)
      _getDevice({ serialNumber: deviceSerialNumber })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return (
    <>
      {maxNumberOfCartridges > 1 && (
        <SectionTitle>
          Port {fuelCartridge.portNumber} / {maxNumberOfCartridges}{' '}
        </SectionTitle>
      )}
      {showMessage && (
        <Alert variant={status >= 400 ? 'danger' : 'success'} onClose={() => setShowMessage(false)} dismissible>
          {status >= 400 ? <Alert.Heading>Error</Alert.Heading> : <Alert.Heading>Success</Alert.Heading>}
          <p>{message}</p>
        </Alert>
      )}
      <ContentDiv>
        {previousId ? (
          <PreviousCartridgeButton icon={['fas', 'chevron-left']} onClick={() => _togglePageDialog({ dialogId: previousId })} />
        ) : (
          <CartridgeButtonDisabled icon={['fas', 'chevron-left']} />
        )}
        <SectionFrame>
          <Form onSubmit={formik.handleSubmit} id="cartridge-form">
            <Form.Group as={Row}>
              <Form.Label column sm="4">
                Current fuel level
              </Form.Label>
              <StyledCol>
                {roundedNumber(fuelCartridge.fuelLevel)} %
                <FuelCartridgeInline>
                  <FuelCartridge fuelCartridge={fuelCartridge}></FuelCartridge>
                </FuelCartridgeInline>
              </StyledCol>
            </Form.Group>

            {maxNumberOfCartridges > 1 && (
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  Port
                </Form.Label>
                <Col>
                  <PermissionCheck value={permissions[DEVICE_DETAILS_PERMISSIONS_LIST.FUEL_PORT_WRITE]} permission={DEVICE_DETAILS_PERMISSIONS_LIST.FUEL_PORT_WRITE}>
                    <ToggleButtonGroup name="portEnabled" type="radio" value={formik.values.portEnabled.toString()}>
                      <ToggleButton id="portEnabled" variant="light" value={true.toString()} onChange={() => formik.setFieldValue('portEnabled', true)} checked={formik.values.portEnabled === true}>
                        Enabled
                      </ToggleButton>
                      <ToggleButton
                        id="portDisabled"
                        variant="light"
                        value={false.toString()}
                        onChange={() => formik.setFieldValue('portEnabled', false)}
                        checked={formik.values.portEnabled === false}
                      >
                        Disabled
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </PermissionCheck>
                </Col>
              </Form.Group>
            )}

            <Form.Group as={Row}>
              <Form.Label column sm="4">
                Type
              </Form.Label>
              <Col>
                <SelectElement name="cartridgeType" onChange={handleTypeChange} value={formik.values.cartridgeType || ''} width="100%" height="42px">
                  <option value={UNSPECIFIED_STR}>UNSPECIFIED</option>
                  <option value={CUSTOM_STR}>CUSTOM</option>
                  <option value="M5">M5</option>
                  <option value="M10">M10</option>
                  <option value="M28">M28</option>
                  <option value="MT60">MT60</option>
                </SelectElement>
              </Col>
            </Form.Group>

            {formik.values.cartridgeType?.toLowerCase() === CUSTOM_STR && (
              <Form.Group as={Row}>
                <Form.Label column sm="4">
                  Capacity (L)
                </Form.Label>
                <Col>
                  <Form.Control min="0" step="1" name="capacity" onChange={formik.handleChange} value={formik.values.capacity} />
                </Col>
              </Form.Group>
            )}

            <Form.Group as={Row}>
              <Form.Label column sm="4">
                New fuel level <br />
                <UpToMessage>0 % up to 100 %</UpToMessage>
              </Form.Label>
              <Col>
              <Form.Control min="0" step="1" disabled={formik.values.cartridgeType?.toLowerCase() === UNSPECIFIED_STR}
                  name="fuelLevel" value={roundedNumber(formik.values.fuelLevel)}
                  onChange={handleInputChange} />
              </Col>
            </Form.Group>
          </Form>
        </SectionFrame>
        {nextId ? <NextCartridgeButton icon={['fas', 'chevron-right']} onClick={() => _togglePageDialog({ dialogId: nextId })} /> : <CartridgeButtonDisabled icon={['fas', 'chevron-right']} />}
      </ContentDiv>

      <ButtonRight type="submit" form="cartridge-form" loading={loading}>
        Save Changes
      </ButtonRight>
    </>
  )
}

export default FuelCartridgesEditDialog
